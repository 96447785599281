import { Box, Button, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../context/AppContext";
import { Cart } from "../components/Cart";
import { CartContext } from "../context/CartContext";
import { DiscountCode } from "../components/DiscountCode";
import { GuestCheckout } from "../components/GuestCheckout";
import { Loading } from "../components/Loading";
import { PaymentProcessor } from "../components/PaymentProcessor";
import ShippingAddressManager from "../components/ShippingAddressManager";
import firebase from "firebase/compat/app";
import { logEvent } from "../services/firebase";
import { useNavigate } from "react-router-dom";

export const Checkout = () => {
  const { member, setOrder, clubId } = useContext(AppContext);
  const { cartItems, getCartTotal, discount, clearCart, addDayPassToCart } =
    useContext(CartContext);
  const [error, setError] = useState(null);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [shippingGroups, setShippingGroups] = useState([]);

  const [guestInfo, setGuestInfo] = useState(null);

  const navigate = useNavigate();
  const total = getCartTotal();

  useEffect(() => {
    logEvent(firebase.analytics.EventName.BEGIN_CHECKOUT, {
      currency: "USD",
      value: getCartTotal(),
      coupon: discount ? discount.code : null,
      items: cartItems.map((item) => ({
        id: item.id,
        sku: item.sku,
        name: item.name,
        category: item.category,
        quantity: item.quantity,
        price: item.amount / 100,
      })),
    });
  }, [cartItems, discount, getCartTotal]);

  const handleShippingUpdate = (newShippingGroups) => {
    setShippingGroups(newShippingGroups);
  };

  const handlePaymentSuccess = (order) => {
    logEvent(firebase.analytics.EventName.PURCHASE, {
      value: total >= 0 ? total / 100 : 0,
      currency: "USD",
      coupon: discount ? discount.code : null,
      items: cartItems.map((item) => ({
        id: item.id,
        sku: item.sku,
        name: item.name,
        category: item.category,
        quantity: item.quantity,
        price: item.amount >= 0 ? item.amount / 100 : 0,
        eventInstanceId: item.eventInstanceId || null,
      })),
    });
    setOrder(order);
    clearCart();
    setProcessingPayment(false);
    navigate(`/thank-you/${order.orderId}`);
  };

  const handlePaymentError = (errorMessage) => {
    setError(errorMessage);
    setProcessingPayment(false);
  };

  return (
    <Container sx={{ pt: 1 }}>
      <Loading
        isLoading={processingPayment}
        message="Processing your payment..."
        messages={[
          "Processing your payment...",
          "Biting the coins...",
          "Holding up to the light...",
          "Calling the bank...",
          "Filling out form #Y-420",
          "Arguing with a computer...",
        ]}
      />
      <Cart />

      {cartItems.length === 0 ? (
        <Box sx={{ textAlign: "center" }}>
          <Button onClick={() => addDayPassToCart()}>Add a Day Pass</Button>
        </Box>
      ) : (
        <>
          <DiscountCode />
          <ShippingAddressManager
            cartItems={cartItems}
            onShippingUpdate={handleShippingUpdate}
          />

          {error && (
            <Typography variant="h5" color="error" gutterBottom>
              {error}
            </Typography>
          )}

          {!member && !guestInfo ? (
            <GuestCheckout onSubmit={setGuestInfo} />
          ) : (
            <PaymentProcessor
              total={total}
              cartItems={cartItems}
              member={member}
              clubId={clubId}
              discount={discount}
              shippingGroups={shippingGroups}
              guestInfo={guestInfo}
              onPaymentSuccess={handlePaymentSuccess}
              onPaymentError={handlePaymentError}
            />
          )}
        </>
      )}
    </Container>
  );
};
