import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Email from "@mui/icons-material/Email";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import Language from "@mui/icons-material/Language";
import { Loading } from "../components/Loading";
import ReactHtmlParser from "react-html-parser";
import SocialMetaTags from "../components/header/SocialMetaTags";
import { db } from "../services/firebase";

const PartnerCard = ({ partner }) => (
  <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
    <CardMedia
      component="img"
      image={partner.image?.src || "/placeholder-image.jpg"}
      alt={partner.name}
      sx={{
        height: 200,
        objectFit: "cover",
      }}
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        {partner.name}
      </Typography>
      <Typography variant="body2" color="text.secondary" paragraph>
        {partner.summary}
      </Typography>
      <Box sx={{ mt: 2 }}>{ReactHtmlParser(partner.content)}</Box>
      <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
        {partner.website && (
          <IconButton
            component={Link}
            href={partner.website}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            <Language />
          </IconButton>
        )}
        {partner.instagram && (
          <IconButton
            component={Link}
            href={partner.instagram}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            <Instagram />
          </IconButton>
        )}
        {partner.facebook && (
          <IconButton
            component={Link}
            href={partner.facebook}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            <Facebook />
          </IconButton>
        )}
        {partner.email && (
          <IconButton
            component={Link}
            href={`mailto:${partner.email}`}
            color="primary"
          >
            <Email />
          </IconButton>
        )}
      </Box>
    </CardContent>
  </Card>
);

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const partnersRef = db.collection("clubs/cola-stl/partners");
        const snapshot = await partnersRef
          .where("isActive", "==", true)
          .orderBy("name")
          .get();

        const partnersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPartners(partnersData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching partners:", err);
        setError("Failed to load partners. Please try again later.");
        setLoading(false);
      }
    };

    fetchPartners();
  }, []);

  if (loading) {
    return <Loading isLoading={loading} message="Loading Partners..." />;
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography color="error" variant="h6" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <SocialMetaTags
        title="Our Partners - The Cola St. Louis"
        description="Meet our amazing partners at The Cola St. Louis. Together we create unique experiences and opportunities for our community."
        image="/cola-stl.svg"
        page="partners"
      />

      <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Our Partners
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          paragraph
        >
          Meet the amazing organizations and businesses we work with
        </Typography>

        <Grid container spacing={4} sx={{ mt: 2 }}>
          {partners.map((partner) => (
            <Grid item key={partner.id} xs={12} md={6}>
              <PartnerCard partner={partner} />
            </Grid>
          ))}
        </Grid>

        {partners.length === 0 && (
          <Typography variant="body1" align="center" sx={{ mt: 4 }}>
            No partners to display at this time.
          </Typography>
        )}
      </Container>
    </>
  );
};

export default Partners;
