import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import React from "react";

const AboutUs = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: "40px",
        minHeight: "100vh",
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          alignItems: "center",
        }}
      >
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: "20px",
              backgroundColor: "#ffffff",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "#333",
                fontWeight: "bold",
                marginBottom: "16px",
              }}
            >
              Welcome to The Cola 🍃
            </Typography>
            <Typography variant="body1" sx={{ color: "#555", lineHeight: 1.8 }}>
              Nestled in the heart of Saint Louis, The Cola is more than just a
              cannabis lounge—it's a vibe, a space where community thrives, and
              connections bloom. As a black-owned, inclusive haven, we’re proud
              to serve as a cultural cornerstone for locals to chill, connect,
              and grow. #WhereBudsMeet 🌱
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#555", lineHeight: 1.8, marginTop: "16px" }}
            >
              Whether you’re here to spark creativity, relax after a long day,
              or attend one of our signature events, The Cola is your go-to
              spot. With volunteer-powered vibes and a whole lot of love, we’re
              keeping the STL community lit in all the best ways.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginTop: "20px",
                backgroundColor: "#4caf50",
                "&:hover": {
                  backgroundColor: "#45a049",
                },
              }}
            >
              Learn More About Us
            </Button>
          </Paper>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/images/the-cola-lounge.jpg" // Replace with your actual image path
            alt="The Cola Lounge"
            sx={{
              maxWidth: "100%",
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
