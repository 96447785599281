import { RouterProvider, createBrowserRouter } from "react-router-dom";

import AboutUs from "./screens/About";
import CheckedIn from "./screens/CheckedIn";
import { Checkout } from "./screens/Checkout";
import { Claim } from "./screens/Claim";
import ConfirmPerformance from "./screens/OpenMic/ConfirmPerformance";
import { ContactUs } from "./screens/ContactUs";
import { DataDeletion } from "./screens/DataDeletion";
import Error from "./components/Error";
import { ErrorBoundary } from "react-error-boundary";
import { EventDetail } from "./screens/EventDetail";
import { EventsList } from "./screens/EventsList";
import FAQ from "./screens/FAQ";
import GiftPage from "./screens/GiftPage";
import Home from "./screens/Home";
import Host from "./screens/Host";
import { Invite } from "./screens/Invite";
import Landing from "./screens/Landing";
import { Layout } from "./components/Layout";
import { MemberOnboarding } from "./screens/MemberOnboarding";
import MobileBudBar from "./screens/MobileBudBar";
import { NotFound } from "./screens/NotFound";
import OpenMicAdmin from "./screens/OpenMic/Admin";
import OpenMicSignup from "./screens/OpenMicSignup";
import POSSystem from "./screens/POSSystem";
import Partners from "./screens/Partners";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { Profile } from "./screens/Profile";
import ReceiptScreen from "./screens/ReceiptScreen";
import { Share } from "./screens/Share";
import SignInScreen from "./screens/SignIn";
import { SignOutPage } from "./screens/SignOut";
import ThankYouPage from "./screens/ThankYouPage";
import VendorForm from "./screens/VendorForm";
import { Waiver } from "./components/Waiver";
import Welcome from "./screens/Welcome";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "checkout", element: <Checkout /> },
      { path: "profile", element: <Profile /> },
      { path: "checked-in", element: <CheckedIn /> },
      { path: "thank-you/:orderId", element: <ThankYouPage /> },
      { path: "privacy", element: <PrivacyPolicy /> },
      { path: "data-deletion", element: <DataDeletion /> },
      { path: "receipt/:orderId", element: <ReceiptScreen /> },
      { path: "terms", element: <Waiver /> },
      { path: "contact", element: <ContactUs /> },
      { path: "invite/:invitation", element: <Invite /> },
      { path: "events/:eventSlug", element: <EventDetail /> },
      { path: "events", element: <EventsList /> },
      { path: "share", element: <Share /> },
      { path: "share/:code", element: <Claim /> },
      { path: "pos", element: <POSSystem /> },
      { path: "welcome", element: <Welcome /> },
      { path: "host", element: <Host /> },
      { path: "open-mic-admin", element: <OpenMicAdmin /> },
      { path: "about", element: <AboutUs /> },
      { path: "faq", element: <FAQ /> },
      { path: "partners", element: <Partners /> },
      {
        path: "/onboarding",
        element: <MemberOnboarding />,
      },
      { path: "/gifts", element: <GiftPage /> },
    ],
  },
  { path: "signout", element: <SignOutPage /> },
  { path: "signin", element: <SignInScreen /> },
  { path: "landing", element: <Landing /> },
  { path: "open-mic-signup", element: <OpenMicSignup /> },
  { path: "open-mic-confirm/:performerId", element: <ConfirmPerformance /> },
  { path: "vendors", element: <VendorForm /> },
  { path: "mobile-bud-bar", element: <MobileBudBar /> },
  { path: "*", element: <NotFound /> },
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function App() {
  return (
    <ErrorBoundary FallBackComponent={Error}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
}
