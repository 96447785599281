import React, { useContext } from "react";

import { AppContext } from "../../context/AppContext";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CartContext } from "../../context/CartContext";
import { CheckInButton } from "../CheckInButton";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)({
  boxShadow: "none",
  position: "relative",
  borderRadius: 0,
  minWidth: 200,
  minHeight: 360,
  "&:after": {
    content: '""',
    display: "block",
    position: "absolute",
    width: "100%",
    height: "64%",
    bottom: 0,
    zIndex: 1,
    background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
  },
});

const VideoWrapper = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  overflow: "hidden",
  zIndex: 0,
});

const StyledVideo = styled("video")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const Content = styled("div")(({ theme }) => ({
  padding: theme.spacing(3, 2),
  position: "absolute",
  zIndex: 2,
  top: 0,
  width: "100%",
  textAlign: "center",
}));

const Title = styled("h1")(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  borderRadius: "1rem",
  fontSize: "2.5rem",
  letterSpacing: "1px",
  fontWeight: "bold",
  textTransform: "initial",
  marginBottom: 0,
  textShadow: "0 0 10px rgba(20, 20, 20, 0.8)",
  padding: theme.spacing(0, 2),
}));

const Subtitle = styled("h2")(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  borderRadius: "1rem",
  fontSize: "1.5rem",
  fontWeight: "bold",
  lineHeight: 1.2,
  textShadow: "0 0 10px rgba(20, 20, 20, 0.8)",

  padding: theme.spacing(0, 2),
}));

const ButtonArea = styled("div")(({ theme }) => ({
  padding: theme.spacing(3, 2),
  position: "absolute",
  zIndex: 3,
  bottom: 0,
  width: "100%",
  textAlign: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  borderRadius: "1rem",
  border: "1px solid #fff",
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.common.fadeToBlack,
  fontSize: "1.75rem",
  lineHeight: 1.2,
  transition: "all 0.5s ease",
  "&:hover": {
    fontSize: "2rem",
    fontWeight: "bold",
  },
}));

export function Header() {
  const { passTemplates, member, isCheckedIn, memberPasses } =
    useContext(AppContext);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const dayPass = passTemplates.find((pass) => pass.type === "day");
  const memberName = member?.displayName || "Guest";

  const signIn = () => {
    navigate("/signin");
  };

  if (isCheckedIn()) {
    return (
      <StyledCard>
        <VideoWrapper>
          <StyledVideo autoPlay loop muted playsInline>
            <source src="/images/thecola.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </StyledVideo>
        </VideoWrapper>
        <Content>
          <img alt="" src="/cola-stl.svg" width={80} height={90} />
          <Title>Welcome Back, {memberName}</Title>
          <Subtitle>Are you happy and high?</Subtitle>
        </Content>
      </StyledCard>
    );
  }

  if (memberPasses && memberPasses.length > 0) {
    const activePass = memberPasses.find((pass) => pass.status === "active");
    const pendingPass = memberPasses.find((pass) => pass.status === "pending");
    const availablePass = activePass || pendingPass;

    if (availablePass) {
      return (
        <StyledCard>
          <VideoWrapper>
            <StyledVideo autoPlay loop muted playsInline>
              <source src="/images/thecola.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </StyledVideo>
          </VideoWrapper>{" "}
          <Content>
            <img alt="" src="/cola-stl.svg" width={80} height={90} />

            <Title>Welcome Back, {memberName}</Title>
            <Subtitle>Check In and Enjoy Your Visit!</Subtitle>
          </Content>
          <ButtonArea>
            <CheckInButton pass={availablePass} />
          </ButtonArea>
        </StyledCard>
      );
    }
  }

  return (
    <StyledCard>
      <VideoWrapper>
        <StyledVideo autoPlay loop muted playsInline>
          <source src="/images/thecola.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      </VideoWrapper>{" "}
      <Content>
        <img alt="" src="/cola-stl.svg" width={80} height={90} />

        <Title>Find Your Spot</Title>
        <Subtitle>Where Buds Meet</Subtitle>
      </Content>
      <ButtonArea>
        <StyledButton onClick={signIn}>Become A Member</StyledButton>
      </ButtonArea>
    </StyledCard>
  );
}
