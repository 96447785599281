import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

const FAQ = () => {
  const faqs = [
    {
      question: "What is The Cola Lounge all about?",
      answer:
        "The Cola is Saint Louis' premier cannabis lounge—a space where community, creativity, and culture collide. We're a black-owned hub where everyone is welcome to chill, connect, and vibe responsibly. #WhereBudsMeet 🌱",
    },
    {
      question: "Do I need a medical card to visit?",
      answer:
        "Nope! Everyone 21 and older is welcome at The Cola. While we don’t sell cannabis on-site, you’re free to bring your own as long as you consume responsibly.",
    },
    {
      question: "What events do you host?",
      answer:
        "We host all kinds of events, from open mic nights and live music to workshops, movie nights, and volunteer meetups. Check out our event calendar for what’s coming up next!",
    },
    {
      question: "Can I volunteer at The Cola?",
      answer:
        "Absolutely! We love when our community gets involved. Reach out to us through our volunteer page or ask one of our staff members how you can contribute.",
    },
    {
      question: "What does it cost to hang out at The Cola?",
      answer:
        "We offer affordable day passes and monthly memberships. Prices start as low as $10s, making The Cola the perfect spot to kick back without breaking the bank.",
    },
    {
      question: "Is The Cola accessible?",
      answer:
        "Yes, we’re committed to making our space inclusive and accessible for everyone. If you have specific needs, let us know, and we’ll do our best to accommodate.",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: "40px",
        minHeight: "100vh",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          marginBottom: "24px",
          color: "#333",
          fontWeight: "bold",
        }}
      >
        Frequently Asked Questions 🌟
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          marginBottom: "32px",
          color: "#555",
        }}
      >
        Got questions? We’ve got answers! If there’s something we missed, don’t
        hesitate to reach out. We’re all about keeping things chill and
        transparent. ✌️
      </Typography>

      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ marginBottom: "8px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`faq-${index}-content`}
            id={`faq-${index}-header`}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" sx={{ color: "#555" }}>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
